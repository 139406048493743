<template>
	<header class="header">
		<RouterLink to="/" class="avatar">
			<img src="~@/assets/images/avatar.jpg" alt="Avatar" width="72" height="72">
		</RouterLink>

		<h1>
			<RouterLink to="/">Alec Rios</RouterLink>
		</h1>
	</header>
</template>

<script>
export default {
	name: 'TheHeader',
};
</script>

<style scoped>
.header {
	margin-top: 1.5rem;
	padding: 1.5rem;
	display: flex;
	align-items: center;
}

.avatar {
	background-color: #4f4f4f;
	border-radius: 50%;
	overflow: hidden;
	margin-right: .75rem;
}
</style>
