<template>
	<div id="app">
		<BaseWrapper>
			<TheHeader/>
			<TheMain/>
		</BaseWrapper>
	</div>
</template>

<script>
import 'core-reset/core-reset.css';
import 'focus-visible';

import BaseWrapper from '@/components/BaseWrapper.vue';
import TheHeader from '@/components/TheHeader.vue';
import TheMain from '@/components/TheMain.vue';

export default {
	name: 'App',
	components: {
		BaseWrapper,
		TheHeader,
		TheMain,
	},
};
</script>

<style>
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src:
		local('Roboto'),
		local('Roboto-Regular'),
		url('./assets/fonts/roboto-v18-latin-regular.woff2') format('woff2'),
		url('./assets/fonts/roboto-v18-latin-regular.woff') format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src:
		local('Roboto Medium'),
		local('Roboto-Medium'),
		url('./assets/fonts/roboto-v18-latin-500.woff2') format('woff2'),
		url('./assets/fonts/roboto-v18-latin-500.woff') format('woff');
}

html {
	font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;;
	background-color: hsla(216, 10%, 10%, 1);
	color: hsla(216, 10%, 80%, 1);
}

::selection {
	background-color: hsla(216, 100%, 80%, .5);
}

.js-focus-visible :focus {
	outline: none;
	box-shadow: 0 0 0 .125rem hsla(216, 100%, 80%, .5);
}

.js-focus-visible :focus:not([data-focus-visible-added]) {
	box-shadow: none;
}

h1 {
	font-size: 1.5rem;
	line-height: 3rem;
}

p {
	font-size: 1rem;
	line-height: 1.5rem;
}

p + p {
	margin-top: 1.5rem;
}

p > a {
	color: hsla(216, 100%, 80%, 1);
	text-decoration: underline;
}
</style>
