<template>
	<main class="main">
		<RouterView/>
	</main>
</template>

<script>
export default {
	name: 'TheMain',
};
</script>

<style scoped>
.main {
	padding: 1.5rem;
}
</style>
