<template>
	<div>
		<BaseLabel :name="name"/>

		<input
			class="input"
			:name="name | toKebabCase"
			:id="name | toKebabCase"
			:placeholder="placeholder"
			:type="type"
			:value="value"
			@input="$emit('input', $event.target.value)"
		>
	</div>
</template>

<script>
import BaseLabel from '@/components/BaseLabel.vue';

export default {
	name: 'BaseInput',
	props: ['name', 'placeholder', 'type', 'value'],
	components: {
		BaseLabel,
	},
};
</script>

<style scoped>
.input {
	display: block;
	width: 100%;
	font-size: 1rem;
	line-height: 1.5rem;
	padding: .75rem 1rem;
	background-color: hsla(216, 10%, 20%, 1);
	border-radius: .25rem;
	color: hsla(216, 10%, 80%, 1);
}
</style>
