<template>
	<div>
		<button
			class="submit"
			type="submit"
			:disabled="disabled"
			:title="disabled ? 'All fields must be filled out before submitting' : ''"
		>
			{{ name }}
		</button>
	</div>
</template>

<script>
export default {
	name: 'BaseSubmit',
	props: ['name', 'disabled'],
};
</script>

<style scoped>
.submit {
	display: block;
	width: 100%;
	font-size: .75rem;
	line-height: 1.5rem;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: .0625rem;
	padding: .75rem 1rem;
	background-color: hsla(216, 75%, 30%, 1);
	border-radius: .25rem;
	color: hsla(216, 10%, 80%, 1);
	transition: background-color 100ms ease;
}

.submit:not(:disabled):hover {
	background-color: hsla(216, 75%, 35%, 1);
	cursor: pointer;
}

.submit:disabled {
	cursor: not-allowed;
}
</style>
