<template>
	<div>
		<TheContactForm/>
	</div>
</template>

<script>
import TheContactForm from '@/components/TheContactForm.vue';

export default {
	name: 'Contact',
	components: {
		TheContactForm,
	},
};
</script>

<style scoped>

</style>
