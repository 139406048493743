import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
import Contact from '@/views/Contact.vue';
import NotFound from '@/views/NotFound.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'Home',
			component: Home,
		},
		{
			path: '/contact',
			name: 'Contact',
			component: Contact,
		},
		{
			path: '*',
			name: 'NotFound',
			component: NotFound,
		},
	],
});
