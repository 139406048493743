<template>
	<div>
		<form
			v-if="formStatus === 'editing'"
			class="form"
			method="post"
			@submit.prevent="handleSubmit"
		>
			<BaseInput
				name="Name"
				placeholder="Your Name"
				type="text"
				v-model="form.name"
			/>

			<BaseInput
				name="Email"
				placeholder="Your Email"
				type="email"
				v-model="form.email"
			/>

			<!-- Honeypot -->
			<BaseInput
				name="Subject"
				placeholder="Your Subject"
				type="text"
				v-model="form.honeypot"
				v-show="false"
			/>

			<BaseTextarea
				name="Message"
				placeholder="Your Message"
				v-model="form.message"
			/>

			<BaseSubmit
				name="Send"
				:disabled="!allFormInputIsValid"
			/>
		</form>

		<div v-if="formStatus === 'sending'">
			<p>Sending...</p>
		</div>

		<div v-if="formStatus === 'success'">
			<p>Thanks for your message. I'll get back to you as soon as I can.</p>
		</div>

		<div v-if="formStatus === 'failure'">
			<p>Sorry, something went wrong. Please try again later.</p>
		</div>
	</div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import BaseTextarea from '@/components/BaseTextarea.vue';
import BaseSubmit from '@/components/BaseSubmit.vue';

export default {
	name: 'TheContactForm',
	components: {
		BaseInput,
		BaseTextarea,
		BaseSubmit,
	},
	data() {
		return {
			formStatus: 'editing',
			form: {
				name: '',
				email: '',
				honeypot: '',
				message: '',
			},
		};
	},
	computed: {
		allFormInputIsValid() {
			return this.form.name && this.form.email && this.form.message;
		},
	},
	methods: {
		encode(data) {
			return Object.keys(data)
				.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
				.join('&');
		},
		handleSubmit() {
			if (!this.allFormInputIsValid) return;

			this.formStatus = 'sending';

			// If the honeypot field is filled out, just pretend to submit
			if (this.form.honeypot) {
				this.formStatus = 'success';
				return;
			}

			fetch('/', {
				method: 'POST',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				body: this.encode({'form-name': 'contact', ...this.form}),
			})
				.then(() => { this.formStatus = 'success'; })
				.catch(() => { this.formStatus = 'failure'; });
		},
	},
};
</script>

<style scoped>
.form > * + * {
	margin-top: 1.5rem;
}

.form > *:last-child {
	margin-top: 3.25rem;
}
</style>
