<template>
	<div>
		<p>Hello, I'm a software developer and designer.</p>

		<p>Some of my most recent side projects are <a href="https://paynano.me" target="_blank" rel="noopener">PayNano</a>, <a href="https://evaluator.link" target="_blank" rel="noopener">Evaluator</a>, <a href="https://speedcube.app" target="_blank" rel="noopener">Speedcube</a>, and <a href="https://onerepmax.app" target="_blank" rel="noopener">One Rep Max</a>.</p>

		<p>You can find more of my work on <a href="https://github.com/alecrios" target="_blank" rel="noopener">GitHub</a> and <a href="https://dribbble.com/alecrios" target="_blank" rel="noopener">Dribbble</a>.</p>

		<p>If you'd like to say hi, feel free to <RouterLink to="/contact">contact me</RouterLink>.</p>
	</div>
</template>

<script>
export default {
	name: 'Home',
};
</script>

<style scoped>

</style>
