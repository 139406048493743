<template>
	<label class="label" :for="name | toKebabCase">{{ name }}</label>
</template>

<script>
export default {
	name: 'BaseLabel',
	props: ['name'],
};
</script>

<style scoped>
.label {
	display: block;
	font-size: .75rem;
	line-height: 1.5rem;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: .0625rem;
	color: hsla(216, 10%, 60%, 1);
	padding-bottom: .25rem;
}
</style>
