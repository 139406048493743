<template>
	<div class="wrapper">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'BaseWrapper',
};
</script>

<style scoped>
.wrapper {
	width: 100%;
	max-width: 32rem;
	margin: 0 auto;
}
</style>
