<template>
	<div>
		<p>Page not found.</p>
	</div>
</template>

<script>
export default {
	name: 'NotFound',
};
</script>

<style scoped>

</style>
