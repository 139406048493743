import Vue from 'vue';
import App from '@/App.vue';
import kebabCase from 'lodash.kebabcase';
import router from '@/router';

Vue.config.productionTip = false;

Vue.filter('toKebabCase', (string) => kebabCase(string));

new Vue({
	router,
	render: (h) => h(App),
}).$mount('#app');
